<!-- eslint-disable -->
<template>
  <div class="w-full h-full" style="padding-right: 5px">
    <el-form
        ref="form"
        :rules="rules"
        :model="form"
        label-width="110px"
        size="small"
    >
      <p class="type-label-before-blue">基本信息</p>
      <el-form-item label="中文名称" prop="name">
        <el-input clearable v-model="form.name"></el-input>
      </el-form-item>
      <el-form-item label="是否分页">
        <el-switch v-model="form.is_paging"></el-switch>
        <el-button
            type="primary"
            style="margin-left: 20px"
            @click="$globalAction('showMetaQoutes', form)"
        >查看引用
        </el-button
        >
        <el-button
            type="primary"
            style="margin-left: 20px"
            @click="$globalAction('ShowViewDocument', {view_uuid:form.uuid})"
        >使用文档
        </el-button>
      </el-form-item>
      <el-form-item label="忽略软删除">
        <el-switch
            v-model="form.no_softdelete"
            :active-value="1"
            :inactive-value="0"
        ></el-switch>
      </el-form-item>
      <el-form-item label="权限绑定">
        <el-input v-model="form.permission"></el-input>
      </el-form-item>
      <el-form-item label="调用参数" v-if="form.uuid">
        <span style="word-break: break-all">
          {object_uuid:'{{ this.obuuid }}',view_uuid:'{{ form.uuid }}'}</span
        >
      </el-form-item>
      <p class="type-label-before-blue" style="top: 50px">
        查询字段
        <span
            style="margin-left: 20px"
            class="cando"
            @click="selectAllFields(false)"
        >全选</span
        >
        / <span class="cando" @click="form.select = []">清空</span> /
        <span class="cando" @click="selectOtherFields(false)">反选</span>
      </p>

      <el-checkbox-group
          v-if="this.fieldList.length != 0"
          key="formselectone"
          v-model="form.select"
      >
        <el-row
            style="
            border-bottom: 1px solid #e7e7e7;
            border-right: 1px solid #e7e7e7;
          "
            class="h-full"
        >
          <el-col
              class="col-item-bd expanded"
              v-for="(v, j) in fieldList"
              :key="j"
              :span="12"
          >
            <el-checkbox :label="v.uuid" class="w-full">
              <MetaTraceIcon method="ShowFieldDetail" :uuid="v.uuid"/>
              <span
                  class="expanded w-full"
                  style="min-width: 160px"
                  :style="{ color: v.type == 'append' ? 'red' : 'black' }"
              >
                {{ v.type == "append" ? "[虚拟] " : "" }}{{ v.name }}-{{
                  v.code
                }}</span
              >
            </el-checkbox>
          </el-col>
        </el-row>
      </el-checkbox-group>

      <!-- 附加关联关系 -->
      <p class="type-label" style="margin-top: 10px">
        附加关联关系 (前端如果传值，以传值为准)
        <span
            style="margin-left: 20px"
            class="cando"
            @click="selectAllFields(true)"
        >全选</span
        >
        / <span class="cando" @click="form.select_relations = []">清空</span> /
        <span class="cando" @click="selectOtherFields(true)">反选</span>
      </p>
      <el-checkbox-group
          v-if="this.relationshipList.length != 0"
          v-model="form.select_relations"
          key="formselect_relationstwo"
      >
        <el-row
            style="
            border-bottom: 1px solid #e7e7e7;
            border-right: 1px solid #e7e7e7;
          "
            class="h-full"
        >
          <el-col
              class="col-item-bd expanded"
              v-for="(relation, index) in relationshipList"
              :key="relation.uuid"
              :span="12"
          >
            <el-checkbox :label="relation.uuid" class="w-full">
              <MetaTraceIcon method="ShowRelationDetail" :uuid="relation.uuid" style="margin-right:5px"/>
              <span class="expanded w-full" style="min-width: 160px"
              >{{ relation.name }}-{{ relation.code }}</span
              >
            </el-checkbox>
          </el-col>
        </el-row>
      </el-checkbox-group>
      <!-- 附加关联关系结束 -->

      <views-tree-form
          class="mt-5"
          ref="treeForm"
          title="查询条件"
          :obuuid="obuuid"
          :formData="formData && formData != null ? [formData.filter] : null"
          :dictionaryList="dictionaryList"
          :relationshipList="relationshipList"
          :fieldList="fieldList"
          :needVirtual="false"
      >
      </views-tree-form>

      <p class="relative mt-5" style="height: 35px;margin-bottom:5px;text-align: left;">
        <span class="type-label-before-blue">排序/分组 方式</span>
        <el-button
            type="primary"
            style="position: absolute; right: 10px"
            @click="addOrder"
            size="mini"
        >新增
        </el-button
        >
      </p>
      <el-table
          :data="form.order"
          ref="dragTable"
          border
          highlight-current-row
          row-key="field_uuid"
      >
        <el-table-column label="排序" width="60" prop="id">
          <template slot-scope="scope">
            <i style="font-size: 18px" class="iconfont icongis_dantihua"/>
          </template>
        </el-table-column>
        <el-table-column label="字段" prop="name">
          <template slot-scope="scope">
            <el-select
                clearable
                class="w-full"
                v-model="scope.row.field_uuid"
                placeholder="请选择"
                size="small"
            >
              <el-option
                  v-for="item in fieldList"
                  :key="item.id"
                  :disabled="checkSame(item.uuid) == '1' || item.type == 'append'"
                  :label="item.name"
                  :value="item.uuid"
              >
              </el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column label="比较值" prop="name">
          <template slot-scope="scope">
            <el-select
                clearable
                class="w-full"
                v-model="scope.row.operator"
                placeholder="请选择"
                size="small"
            >
              <el-option
                  v-for="item in orderType"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value"
              >
              </el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="80">
          <template slot-scope="scope">
            <el-popconfirm
                @confirm="delOrder(scope.rowIndex)"
                title="确定删除吗？"
            >
              <span class="operation-del" slot="reference">删除</span>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
    </el-form>
  </div>
</template>

<script>
import object from "@/api/objects";
import Sortable from "sortablejs";
import viewsTreeForm from "@/views/objects/components/viewstreeForm.vue";
import MetaTraceIcon from "@/components/metaSelect/MetaTraceIcon.vue";
import {CheckboxGroup, Popconfirm} from "element-ui";

export default {
  name: "viewsForm",
  components: {
    viewsTreeForm,
    MetaTraceIcon,
    'el-checkbox-group': CheckboxGroup,
    'el-popconfirm': Popconfirm,
  },
  props: {
    formData: {
      type: Object,
      default: null,
    },
    obuuid: {
      type: String,
      default: "global",
    },
    dictionaryList: {
      type: Array,
      default: () => [],
    },
    fieldList: {
      type: Array,
      default: () => [],
    },
    relationshipList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      operators: object.operators,
      form: {
        name: "",
        is_paging: false,
        select: [], //选中字段
        filter: [], //筛选条件
        order: [], //排序条件
        no_softdelete: 0,
        permission: "",
        select_relations: [], //选中的关联关系
      },
      rules: {
        code: [{required: true, message: "请输入", trigger: "blur"}],
        name: [{required: true, message: "请输入", trigger: "blur"}],
        comment: [{required: true, message: "请输入", trigger: "blur"}],
        type: [{required: true, message: "请选择", trigger: "change"}],
      },
      filterType: object.filterType, //筛选方式
      orderType: object.orderType, //排序方式
      editMode: false,
    };
  },
  computed: {},
  methods: {
    /**
     * @description: 选择全部字段
     * @param {Boolean} relation 是否是关联关系全选
     * @return {Void}
     */
    selectAllFields(relation = false) {
      let all = [];
      if (!relation) {
        this.fieldList.forEach((item) => {
          all.push(item.uuid);
        });
        this.$nextTick(() => {
          this.form.select = all;
        });
      } else {
        this.relationshipList.forEach((item) => {
          all.push(item.uuid);
        });
        this.form.select_relations = all;
      }
    },

    /**
     * @description: 反选所有字段
     * @param {Boolean} relation 是否是关联关系反选
     * @return {void}
     */
    selectOtherFields(relation = false) {
      let all = [];

      if (!relation) {
        let origin = JSON.parse(JSON.stringify(this.form.select));
        this.fieldList.forEach((item) => {
          if (origin.indexOf(item.uuid) == -1) {
            all.push(item.uuid);
          }
        });
        this.form.select = all;
      } else {
        let origin = JSON.parse(JSON.stringify(this.form.select_relations));
        this.relationshipList.forEach((item) => {
          if (origin.indexOf(item.uuid) == -1) {
            all.push(item.uuid);
          }
        });
        this.form.select_relations = all;
      }
    },

    setSort() {
      const el = this.$refs.dragTable.$el.querySelectorAll(
          ".el-table__body-wrapper > table > tbody"
      )[0];
      this.sortable = Sortable.create(el, {
        ghostClass: "sortable-ghost",
        setData: function (dataTransfer) {
          dataTransfer.setData("Text", "");
        },
        onEnd: (evt) => {
          const targetRow = this.form.order.splice(evt.oldIndex, 1)[0];
          this.form.order.splice(evt.newIndex, 0, targetRow);
        },
      });
    },
    checkSame(uuid) {
      if (this.form.order.length == 0) {
        return "0";
      } else {
        let arr = this.form.order.filter((v) => v.field_uuid == uuid);
        if (arr.length == 0) {
          return "0";
        } else {
          return "1";
        }
      }
    },
    getName(res, data, type) {
      let arr = data.filter((v) => v[type] == res);
      if (arr.length != 0) {
        return arr[0].name;
      } else {
        return "";
      }
    },
    addOrder() {
      this.form.order.unshift({
        field_uuid: "",
        operator: "",
      });
    },
    delOrder(i) {
      this.form.order.splice(i, 1);
    },
    commit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          let data = JSON.parse(JSON.stringify(this.form));
          let filter = this.$refs.treeForm.data[0];
          // if (filter.children.length == 0) {
          //   this.$message({
          //     type: "error",
          //     message: "验证逻辑不能为空",
          //   });
          //   this.$emit("closeLoading");
          //   return;
          // }
          data.filter = JSON.parse(JSON.stringify(filter));
          this.$emit("addFn", data);
        } else {
          this.$emit("closeLoading");
        }
      });
    },
  },
  created() {
    if (this.fieldList) {
      if (this.fieldList.length != 0) {
        this.fieldList.forEach((item) => {
          this.form.select.push(item.uuid);
        });
      }
    }
    if (this.relationshipList) {
      if (this.relationshipList.length != 0) {
        this.relationshipList.forEach((item) => {
          this.form.select_relations.push(item.uuid);
        });
      }
    }
  },
  mounted() {
    this.setSort();
    if (this.formData != null) {
      let form = JSON.parse(JSON.stringify(this.formData));
      // 数据处理好了再赋值，不然中间变成 undefined 了过后组件可能会找不到值
      if (form.select_relations === undefined || !form.select_relations) {
        form.select_relations = [];
      }
      this.form = form;
    } else {
      this.addOrder();
    }
  },
};
</script>

<style lang="less">
.col-item-bd {
  padding: 5px;
  width: 50%;
  border-top: 1px solid #e7e7e7;
  text-align: left;
}

.col-item-bd:nth-child(odd) {
  border-left: 1px solid #e7e7e7;
  border-right: 1px solid #e7e7e7;
}

.col-item-bd .el-checkbox__label {
  display: inline-block;
  width: calc(100% - 22px);
  position: relative;
  top: 4px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.cando {
  cursor: pointer;
  color: burlywood;
}
</style>
