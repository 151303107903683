/*
 * @LastEditTime: 2022-08-05 15:05:58
 * @Description: 以弹窗的形式展示视图配置
 * @FilePath: /dataview-next/src/global/actions/ShowViewForm.js
 * @Date: 2022-04-13 16:31:33
 * @Author: lisushuang
 * @LastEditors: lisushuang
 */
/* eslint-disable */

import baseAction from "./baseAction"
import ViewsForm from "@/views/objects/components/viewsForm"
import ShowViewData from "./ShowViewData"
import objects from "../../api/objects"
import Vue from 'vue';
import { Button, Message } from "element-ui";

export default class ShowViewForm extends baseAction{

  viewData = null

  constructor(){
    super()
  }

  /**
   * @description: 核心处理方法
   */  
  handler()
  {
    this.getLoading("查询中")
    let _this = this
    let view_uuid = null
    if(this.props.view_uuid){
      view_uuid = this.props.view_uuid
    }else if(this.props.uuid){
      view_uuid = this.props.uuid
    }else{
      view_uuid = this.props
    }

    if(!view_uuid){
      this.showError('展示视图详情：错误的参数！')
      return
    }
    let viewData = null
    let objectData = null
    objects.getOneView(view_uuid).then(res => {
      if(res.data.code == 200){
        viewData = res.data.data
        this.viewData = viewData
        objects.getOne(viewData.object_uuid).then(res=>{
          if(res.data.code ==200){
            objectData = res.data.data

            let content = () => {
                let h = this.mountInstance.$createElement
                return (
                  <ViewsForm 
                    obuuid={objectData.uuid}
                    formData={viewData}
                    relationshipList={objectData.relationships.owner}
                    fieldList={objectData.fields}
                    ref="viewsFormInstance"
                    onAddFn={(value) => this.saveView(value)}
                  />
                )
            }
            this.showPopDialog(
              content,
              '视图：' + objectData.name + ' - - ' + viewData.name,
              this.footer
            )
            
          }else{
            this.showError(res.data.msg)
          }
          this.loading.close()
        })
      }else{
        this.loading.close()
        this.showError(res.data.msg)
      }
      
    }).catch(res=>this.loading.close())
    
  }

  /**
   * @description: 保存视图配置
   * @param {Object} value 修改好的视图配置信息
   * @return {Void}
   */  
  saveView(value){
    let originValue = JSON.parse(JSON.stringify(value))
    this.getLoading("保存中")
    delete value.object
    delete value.object_id
    delete value.created_at
    delete value.updated_at
    delete value.id
    objects.editView(value.uuid,value).then(res => {
      this.loading.close()
      this.viewData = originValue
      if(res.data.code == 200){
        Message.success("视图修改成功！")
      }else{
        this.showError(res.data.msg)
      }
    }).catch(res=>{
      this.loading.close()
      this.showError("保存视图数据失败！")
    })
  }

  footer = () => {
    let h = this.mountInstance.$createElement
    return (
      <div>
        <Button type="success" onClick={()=> this.mountInstance.$refs.viewsFormInstance.commit()}> 保存视图设置 </Button>
        <Button type="info" onClick={()=> new ShowViewData().data(this.viewData).handler()}> 展示视图数据 </Button>
      </div>
    )
  }

  
}