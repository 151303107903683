<template>
  <div class="custom-tree-container">
    <p class="type-label-before-blue red-star " v-if="showTitle">{{ title }}</p>
    <el-tree
      :data="data"
      node-key="id"
      style="background: #f5f5f5; padding-right: 3px;text-align: left;"
      class="py-5"
      default-expand-all
      :expand-on-click-node="false"
    >
      <div class="custom-tree-node w-full" slot-scope="{ node, data }">
        <span
          style="display: inline-block; width: calc(100% - 40px)"
          v-if="!data.operator && data.id !== 0"
        >
          <el-popover
            popper-class="pop-value-form scroller"
            :placement="formData == null ? 'left' : 'right'"
            width="500"
            trigger="click"
          >
            <p class="type-label-before-blue">左侧取值</p>
            <el-select
              clearable
              class="w-full"
              v-model="data.leftValue"
              placeholder="请选择左侧值"
            >
              <el-option
                v-for="item in fieldList"
                :key="'field1' + item.id"
                :label="item.name + item.uuid"
                :disabled="!needVirtual && item.type == 'append'"
                :value="item.uuid"
              >
              </el-option>
            </el-select>
            <p class="type-label-before-blue mt-5">比较方式</p>
            <el-select
              clearable
              class="w-full mb-5"
              v-model="data.ruleType"
              placeholder="请选择比较值"
            >
              <el-option
                v-for="item in filterType"
                :key="'type' + item.value"
                :label="item.name"
                :value="item.value"
              >
              </el-option>
            </el-select>
            <value-Form
              ref="rightValue"
              title="右侧取值"
              realTime
              @change-data="changeData($event, 'rightValue', data, node)"
              :obuuid="obuuid"
              :formData="data.rightValue ? data.rightValue : null"
              :dictionaryList="dictionaryList"
              :fieldList="fieldList"
              :relationshipList="relationshipList"
            />
            <div class="w-full" slot="reference">
              <span v-if="data.leftValue == null" class="col-theme"
                >左侧取值</span
              >
              <span
                v-else
                v-html="
                  fieldList.filter((v) => v.uuid == data.leftValue).length != 0
                    ? fieldList.filter((v) => v.uuid == data.leftValue)[0].name
                    : ''
                "
              ></span>
              <span v-if="data.ruleType == ''" class="col-theme mx-5"
                >比较值</span
              >
              <span
                v-else
                v-html="
                  filterType.filter((v) => v.value == data.ruleType)[0].name
                "
                class="mx-5"
              ></span>
              <span
                v-if="
                  data &&
                  data.rightValue &&
                  data.rightValue.class &&
                  data.rightValue.payload &&
                  data.rightValue.class == 'ConstValue'
                "
              >
                {{ data.rightValue.payload.const_value }}
              </span>
              <span v-else class="col-theme">右侧取值</span>
            </div>
          </el-popover>
        </span>
        <span v-else>{{ data.label }}</span>
        <span>
          
          <!-- 条件成立的规则 -->
          <el-popover
          popper-class="pop-value-form scroller"
          :placement="formData == null ? 'left' : 'right'"
          width="500"
          trigger="click"
          >
            <TreeForm
              title="条件成立规则：规则成立时本条件生效，否则跳过本条件"
              v-model="data.condition"
              :formData="data.condition ? [data.condition] : null"
              :obuuid="obuuid"
            ></TreeForm>
            <i class="el-icon-setting" v-if="!data.condition" slot="reference" style="margin-right: 4px;"></i>
            <i class="el-icon-s-tools" v-else slot="reference" style="margin-right: 4px;color:#5a9cfe;font-weight: bold;"></i>
          </el-popover> 
          <!-- 条件规则结束 -->

          <el-popconfirm
            v-if="data.operator == ''"
            confirm-button-text="且：全部成立才可"
            cancel-button-text="或：有一个成立即可"
            icon="el-icon-info"
            icon-color="red"
            @confirm="append(data, 'and')"
            @cancel="append(data, 'or')"
            title="请选择逻辑"
          >
            <span slot="reference">
              <el-button type="primary" v-if="data.id == 0" size="mini"
                >新增</el-button
              >
              <i v-if="data.id != 0" class="el-icon-plus"></i>
              <i
                v-if="data.id != 0"
                @click="remove(node, data)"
                class="el-icon-delete ml-2"
              ></i>
            </span>
          </el-popconfirm>
          <span v-else>
            <span v-if="data.id == 0">
              <el-button
                type="primary"
                slot="reference"
                @click="append(data, data.operator)"
                size="mini"
                >新增</el-button
              >
            </span>
            <span v-else>
              <i
                @click="append(data, data.operator)"
                v-if="data.operator != ''"
                class="el-icon-plus"
              ></i>
              <i @click="remove(node, data)" class="el-icon-delete ml-2"></i>
            </span>
          </span>
        </span>
      </div>
    </el-tree>
  </div>
</template>
<script>
/* eslint-disable */
import object from "@/api/objects";
import valueForm from "@/views/objects/components/valueForm.vue";
import TreeForm from "./treeForm.vue";
import {Tree, Popconfirm,Popover } from "element-ui";

let id = 1000;
export default {
  data() {
    const data = [
      {
        id: 0,
        label: "默认",
        operator: "",
        children: [],
      },
    ];
    return {
      data: JSON.parse(JSON.stringify(data)),
      operator: "and",
      filterType: object.filterType, //筛选方式
    };
  },
  watch: {
    data: {
      handler(){
        this.$emit('input', this.data);
      },
      deep: true,
    }
  },
  components: {
    valueForm,
    TreeForm,
    'el-tree': Tree,
    'el-popconfirm': Popconfirm,
    'el-popover': Popover,
},
  props: {
    fieldList: {
      type: Array,
      default: () => [],
    },
    dictionaryList: {
      type: Array,
      default: () => [],
    },
    relationshipList: {
      type: Array,
      default: () => [],
    },
    formData: {
      type: Array,
      default: () => [],
    },
    obuuid: {
      type: String,
      default: String,
    },
    title: {
      type: String,
      default: "验证逻辑",
    },
    showTitle: {
      type: Boolean,
      default: true,
    },
    // 是否需要虚拟字段
    needVirtual: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    append(data, type) {
      if (data.operator == "") {
        data.operator = type;
      }
      let name = type == "and" ? "且：全部成立才可" : "或：有一个成立即可";
      if (data.label == "默认") {
        data.label = name;
      }
      // console.log(this.formData[0])
      let sid = Math.random().toString(36).substring(3);
      const newChild = {
        id: sid,
        label: "默认",
        children: [],
        operator: "",
        ruleType: "",
        leftValue: null,
        rightValue: null,
      };
      if (!data.children) {
        this.$set(data, "children", []);
      }
      data.children.push(newChild);
    },

    remove(node, data) {
      const parent = node.parent;
      const children = parent.data.children || parent.data;
      const index = children.findIndex((d) => d.id === data.id);
      children.splice(index, 1);
      if (parent.data.children && parent.data.children.length == 0) {
        parent.data.label = "默认";
        parent.data.operator = "";
      }
    },
    changeData(e, name, data, node) {
      const parent = node.parent;
      const children = parent.data.children || parent.data;
      const index = children.findIndex((d) => d.id === data.id);
      children[index][name] = e;
    },
  },
  mounted() {
    if (this.formData != null) {
      this.data = JSON.parse(JSON.stringify(this.formData));
      if (
        this.formData.filter &&
        this.formData.filter.children &&
        this.formData.filter.children.length
      ) {
        id += this.formData.filter.children.length + 10;
      }
    }
  },
};
</script>

<style >
.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
}
</style>